<template>
  <transition
    :css="false"
    appear
    @appear="onAppear"
  >
    <slot></slot>
  </transition>
</template>

<script>
import { gsap, ScrollTrigger } from '@/common/motions';

/**
 * 커버 Transition 컴포넌트
 * @see https://vuejs.org/v2/guide/transitions.html#JavaScript-Hooks
 * @see https://vuejs.org/v2/guide/transitions.html#Transitions-on-Initial-Render
 * @see ScrollTrigger https://greensock.com/docs/v3/Plugins/ScrollTrigger
 * @see https://greensock.com/forums/topic/24423-how-to-snap-instantly-on-slight-scroll/
 */
export default {
  name: 'CoverTransition',

  props: {
    /**
     * 스크롤 대상
     */
    scrollToTarget: {
      type: [String, Object],
    },
    offSetY: {
      type: [Number],
      default: 150,
    },

    /**
     * ID
     */
    id: {
      type: String,
      default() {
        // https://stackoverflow.com/a/12502559
        return `ct-${Math.random().toString(36).slice(2)}`;
      },
    },
  },

  methods: {
    onAppear(el, done) {
      // 스크롤 대상이 있는 경우에만
      if (this.scrollToTarget) {
        // 커버 스크롤 모션
        const offsetY = this.offSetY;

        this.scrollToScrollTrigger = ScrollTrigger.create({
        // markers: true,
          trigger: el,
          start: 'bottom 99%',
          end: `bottom 1%+=${offsetY / 2}`,

          onEnter: () => { // { progress, direction, isActive }
          // console.log('onEnter', progress, direction, isActive);
            gsap.to(window, {
              scrollTo: { y: this.scrollToTarget, autoKill: false, offsetY },
              duration: 0.5,
            });
          },

          onEnterBack: () => { // { progress, direction, isActive }
          // console.log('onEnterBack', progress, direction, isActive);
            gsap.to(window, {
              scrollTo: { y: el, autoKill: false },
              duration: 0.5,
            });
          },
        });
      }

      // 커버 배경 모션
      gsap.from(
        el,
        {
          scrollTrigger: {
            id: `bg-${this.id}`,
            trigger: el,
            // markers: true,
            // onEnter: () => {
            //   console.log('onEnter', this.id);
            // },
          },
          scale: 1.2,
          autoAlpha: 0,
          duration: 2.8,
          ease: 'Power4.Inout',
        },
      );

      done();
    },
  },

  destroyed() {
    if (this.scrollToScrollTrigger) {
      this.scrollToScrollTrigger.kill();
    }

    const backgroundTrigger = ScrollTrigger.getById(`bg-${this.id}`);
    if (backgroundTrigger) {
      backgroundTrigger.kill();
    }
  },

};
</script>


<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>
기본 사용 예시 :
```jsx
<section
  class="section cover-section">
  <CoverTransition>
    <div class="section-bg program-bg"></div>
  </CoverTransition>
  <h2 class="split-title">BUSINESS ENABLER DREAMPLUS</h2>
</section>

<section class="title-section">
  <div class="title-wrap">
    <!-- WITH HANWHA PROGRAM -->
    <h3>WITH HANWHA PROGRAM</h3>
    <div class="title-desc">드림플러스는 스타트업에게는 성장을, 기업에게는 혁신의 기회를, 일반 대중에게는 창 ·취업 지원활동 기회를 제공합니다.</div>
  </div>
</section>
```
</docs>
