<template>
  <Swiper
    :options="options"
    @slideChange="onChange"
  >
    <SwiperSlide
      v-for="(list, groupedIndex) in groupedList"
      :key="groupedIndex"
    >
      <template v-for="(item) in list">
        <!--
          @slot 슬라이드 그룹내 데이터 1개에 대한 UI
            @binding item 데이터
        -->
        <slot name="default" :item="item"></slot>
      </template>
    </SwiperSlide>
    <template v-slot:pagination>
      <div class="swiper-pagination"></div>
    </template>
    <template v-slot:button-next>
      <div class="swiper-button-prev"></div>
    </template>
    <template v-slot:button-prev>
      <div class="swiper-button-next"></div>
    </template>
  </Swiper>
</template>

<script>
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

/**
 * 그룹 슬라이더 컴포넌트
 * @see https://swiperjs.com/demos/#multi_row_slides_layout
 * @see https://github.com/surmon-china/vue-awesome-swiper
 * @see https://swiperjs.com/
 */
export default {
  name: 'GroupedSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    /**
     * 슬라이드 대상 데이터 리스트
     */
    list: {
      type: Array,
    },
    /**
     * 슬라이드 그룹당 데이터 수
     */
    itemsPerGroup: {
      type: Number,
      default: 1,
    },
    /**
     * 자동 슬라이드 여부
     */
    autoplay: {
      type: Boolean,
      default: true,
    },
    /**
     * 순환 여부
     */
    loop: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    groupedList() {
      return _.chunk(this.list, this.itemsPerGroup);
    },
  },
  data() {
    return {
      /**
       * @see https://swiperjs.com/api/#parameters
       */
      options: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: this.loop,
        /**
         * @see https://swiperjs.com/api/#autoplay
         */
        autoplay: this.autoplay
          ? {
            disableOnInteraction: false,
          }
          : false,
        /**
         * @see https://swiperjs.com/api/#pagination
         */
        pagination: this.pagination ? {
          el: '.swiper-pagination',
          clickable: true,
        } : false,
        /**
         * @see https://swiperjs.com/api/#navigation
         */
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
      },
    };
  },
  methods: {
    /**
     * 슬라이드 변경시 이벤트 처리
     * @type {number} index
     */
    onChange() {
      /**
       * 변경 이벤트
       * @event change
       * @property {number} 현재 슬라이드 index
       */
      this.$emit('change', this.$el.swiper.realIndex);
    },
  },
};
</script>

<style scoped>
@import '~swiper/css/swiper.css';
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>
기본 사용 예시 :
```jsx
<GroupedSlider
  :list="[
    'https://www.dreamplus.asia/static/favicon/android-icon-36x36.png',
    'https://www.dreamplus.asia/static/favicon/android-icon-48x48.png',
    'https://www.dreamplus.asia/static/favicon/android-icon-72x72.png',
    'https://www.dreamplus.asia/static/favicon/android-icon-96x96.png',
    'https://www.dreamplus.asia/static/favicon/android-icon-144x144.png',
    'https://www.dreamplus.asia/static/favicon/android-icon-192x192.png',
    'https://www.dreamplus.asia/static/favicon/apple-icon-57x57.png',
    'https://www.dreamplus.asia/static/favicon/apple-icon-60x60.png',
    'https://www.dreamplus.asia/static/favicon/apple-icon-72x72.png',
    'https://www.dreamplus.asia/static/favicon/apple-icon-76x76.png',
    'https://www.dreamplus.asia/static/favicon/apple-icon-114x114.png',
    'https://www.dreamplus.asia/static/favicon/apple-icon-120x120.png',
    'https://www.dreamplus.asia/static/favicon/apple-icon-144x144.png',
    'https://www.dreamplus.asia/static/favicon/apple-icon-152x152.png',
    'https://www.dreamplus.asia/static/favicon/apple-icon-180x180.png',
  ]"
  :itemsPerGroup="4"
>
  <template v-slot="{ item }">
    <img :src="item" />
  </template>
</GroupedSlider>
```
</docs>
